.typography {
  white-space: pre-line;
  color: var(--grey-dark-color);
}

.color-green {
  color: var(--green-color);
}

.color-blue {
  color: var(--blue-color);
}

.color-grey-dark {
  color: var(--grey-dark-color);
}
