.themeMode {
  position: fixed;
  bottom: 20px;
  right: 20px;
  opacity: 0.7;
  cursor: pointer;
  transition: 300ms;
}

.themeMode:hover {
  opacity: 1;
}
