.button {
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  background-color: var(--grey-light-color);
  border: 1px solid var(--border-color);
  color: var(--blue-color);
  padding-inline: 23px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  transition: 300ms;
}

.button:hover {
  background-color: var(--blue-color);
  border: 1px solid var(--blue-color);
  color: white;
}

.size-default {
  height: 60px;
  font-size: 16px;
  padding-inline: 86px;
}

.size-small {
  height: 38px;
  font-size: 14px;
}
