.missionBox {
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgb(240, 251, 254),
    rgb(255, 255, 255) 100%
  );
  padding-inline: 20px;
  padding-block: 23px;
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
