.about {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.blueFallSvg {
  position: absolute;
  left: 0;
  bottom: -170px;
  z-index: -1;
  width: auto;
  height: 100%;
}

.aboutInformation {
  width: 100%;
}

.description {
  line-height: 30px;
  margin-top: 12px;
  margin-bottom: 37px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}

.statistic {
  display: flex;
  flex-wrap: wrap;
  max-width: 424px;
  gap: 20px;
}

.statisticBox {
  width: 200px;
  height: 200px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 300ms;
}

.statisticBoxPositive {
  margin-top: 110px;
}

.statisticBoxNegative {
  margin-top: -110px;
}

.statisticBox:hover {
  color: var(--white-color);
  background-color: var(--green-color);
  border-color: var(--green-color);
  transform: scale(1.1);
}

.statisticBox:hover .statisticBoxText {
  color: var(--white-color) !important;
}
