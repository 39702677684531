.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 1200px) {
  .container {
    width: 1188px;
  }
}
