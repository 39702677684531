.header {
  width: 100%;
  height: 55px;
  position: fixed;
  background-color: var(--white-color);
  border-bottom: 1px dashed var(--border-color);
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 10;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 136px;
  height: 34px;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 31px;
}

.link {
  position: relative;
  font-size: 16px;
  color: var(--grey-dark-color);
  font-weight: 400;
  height: 55px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 300ms;
}

.link:hover {
  color: var(--blue-color);
}

.linkDisactive::after {
  bottom: -10;
  transition: 500ms;
}

.linkActive {
  color: var(--blue-color);
  transition: 300ms;
}

.link::after {
  content: '';
  width: 100%;
  position: absolute;
  bottom: -10px;
  background-color: var(--blue-color);
  height: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 500ms;
}

.linkActive::after {
  bottom: 0px;
}
