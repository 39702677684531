@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

:root {
  --white-color: white;
  --green-color: #119b8a;
  --blue-color: #469ede;
  --grey-dark-color: #3b4b5d;
  --grey-light-color: #f0fbfe;
  --border-color: #d3e1e4;
}

body[data-theme='dark'] {
  background-color: black;
  --white-color: black;
  --grey-dark-color: white;
  --border-color: #262929;
  --grey-light-color: #1b1c1c;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.sections {
  padding-top: 55px;
}

.sections section {
  scroll-margin-top: 55px;
}
