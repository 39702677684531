.home {
  height: calc(100vh - 55px);
  display: flex;
  align-items: center;
}

.detailsButton {
  margin-top: 33px;
}

.languages {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 14px;
}

.description {
  margin-top: 8px;
}

.sectionGreenFall {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  height: 100%;
  width: auto;
}

.icon {
  width: 32px;
  height: 32px;
  filter: grayscale(1);
  cursor: pointer;
  opacity: 0.5;
  transition: 300ms;
}

.icon:hover {
  opacity: 1;
  filter: grayscale(0);
}
